@import url('https://fonts.googleapis.com/css2?family=Lato@1&display=swap');

@font-face {
  font-family: 'tex_gyre_herosbold';
  src: url('./fonts/texgyreheros-bold-webfont.woff') format('woff'),
    url('./fonts/texgyreheros-bold-webfont.svg#tex_gyre_herosbold') format('svg');
  font-weight: normal;
  font-style: normal;
}


