@import url(https://fonts.googleapis.com/css2?family=Lato@1&display=swap);
@font-face {
  font-family: 'tex_gyre_herosbold';
  src: url(/static/media/texgyreheros-bold-webfont.3957a0bd.woff) format('woff'),
    url(/static/media/texgyreheros-bold-webfont.1b1ece9e.svg#tex_gyre_herosbold) format('svg');
  font-weight: normal;
  font-style: normal;
}



